import React from 'react';
import Default from '../../../layouts/default';
import useAlgoliaSearchAPI from '../../../hooks/useAlgoliaSearchAPI';
import algoliaIndices from '../../../../algolia-indices';
import { BLOG_CATEGORIES } from '../../../constants';
import EmailSubscribeBanner from '../../../components/EmailSubscribeBanner';
import useBlogHeader from '../../../hooks/useBlogHeader';
import { graphql } from 'gatsby';
import { ContentfulPageBlogConnection } from 'GatsbyTypes';
import BlogWrapper from '../../../components/Blog/Search';
import { BlogPostSection } from '../../../components/Blog/BlogPostSection';

interface PageData {
  data: {
    blog: ContentfulPageBlogConnection;
  };
  params: {
    id: string;
  };
}

const BlogCategory: any = ({ data: { blog }, params: { id } }: PageData) => {
  const category = Object.values(BLOG_CATEGORIES)[Object.keys(BLOG_CATEGORIES).indexOf(id)];

  const {
    data: categoryPosts,
    incrementPage: incrementCategoryPage,
    hasMoreToShow: hasMoreToShowCategoryPosts,
  } = useAlgoliaSearchAPI(algoliaIndices.blogCategories, category);

  const {
    data: fixGuidePosts,
    incrementPage: incrementFixGuidesPage,
    hasMoreToShow: hasMoreToShowFixGuidePosts,
  } = useAlgoliaSearchAPI(algoliaIndices.blogCategories, BLOG_CATEGORIES.vulnerabilitiesAndRemediation);

  const { BlogHeader } = useBlogHeader(blog);

  return (
    <Default>
      <BlogHeader />
      <main className="container">
        <BlogWrapper query={id}>
          <BlogPostSection
            subtitle={category}
            edges={categoryPosts}
            isLoadMoreButtonVisible={hasMoreToShowCategoryPosts}
            onLoadMore={incrementCategoryPage}
          />

          <BlogPostSection
            title="Fix Guides"
            edges={fixGuidePosts}
            isLoadMoreButtonVisible={hasMoreToShowFixGuidePosts}
            onLoadMore={incrementFixGuidesPage}
          />
        </BlogWrapper>
      </main>
      <section>
        <EmailSubscribeBanner />
      </section>
    </Default>
  );
};

export default BlogCategory;

export const query = graphql`
  {
    blog: allContentfulPageBlog(limit: 1) {
      edges {
        node {
          seo {
            ...ContentfulSeo
          }
          ...blogFields
        }
      }
    }
  }
`;
